

.profile-icon-container {
  width: 43px;
  height: 43px;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-icon-container svg {
  color: var(--primary-black);
  width: 21px;
  height: 28px;
}