
.sidebar {
  width: 265px;
  height: 78vh;
  margin: 125px 12px 20px 19px;
}

/* .sidebar-container {
  display: grid;
  align-content: space-between;
  width: 265px;
  position: relative;
} */

/* .inner-sidebar-container {
  position: fixed;
  display: grid;
  align-content: space-between;
  width: inherit;
  height: -webkit-fill-available;
  margin-bottom: 20px;
} */

.sidebar-container .sidebar-header-item,
.sidebar-container .sidebar-footer-item {
  display: grid;
  gap: 0.5rem;
  width: inherit;
}

.sidebar-element {
  display: flex;
  gap: 6px;
  align-items: center;
  width: auto;
  cursor: pointer;
  border-radius: 50px;
  padding: 13.5px;
  color: var(--primary-black);
  text-decoration: none;
  padding-right: 0;
}

.sidebar-element svg {
  height: 24px;
  width: 24px;
}


/* Fonts  */
.sb-opt-font {
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 700;
}

.sidebar-element p {
  margin: 0;
}

.sidebar-header-item .active {
  background: var(--primary-mandy);
  color: var(--primary-lite-white);
}

/* notification dot  */
.notify-dot {
  border-radius: 10px;
  background: var(--primary-mandy);
  width: 8px;
  height: 8px;
  margin-left: -6px;
  margin-top: -16px;
}

.active .notify-dot {
  background: var(--primary-lite-white);
}


main {
  width: 100%;
  background: var(--primary-lite-mandy);
  overflow: auto;
  margin-left: 245px;
}

main .page-content {
  margin: 0 4rem;
  margin-top: 1.4rem;
  width: auto;
}

.profile-viewer a, .navlink {
  text-decoration: none;
  color: currentColor;
}



/* Laptop 1080 to 1280 */
@media (max-width: 1280px) {
  .sidebar {
    width: 230px;
    margin-left: 14px;
  }

  .sidebar > .logo-container {
    width: 154px;
    left: 15px;
  }
  .sidebar > .sidebar-container {
    width: 230px;
  }

  main .page-content {
    margin: 0 2.5rem;
    margin-top: 1.4rem;
  }
}


/* Mobile  */
@media (max-width: 431px) {

  .sidebar {
    display: none;
  }

  main {
    margin: 0;
  }

  main .page-content {
    margin: 0;
  }

  header.after-login {
    margin: 20px 12px;
    z-index: 2;
    position: relative;
  }

}