.product-listing-page-content-body {
  margin-top: 4rem;
}

/* Products content container heading section  */
.products-heading-section {
  margin-bottom: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.products-heading-section h2 {
  margin: 0;
  font-size: 39px;
  line-height: 46.8px;
  font-weight: 700;
}

/* Products container section css  */
.products-container-section .products-row .psp-a-product {
  display: inline-block;
}

/* Filter Button  */
.filter-btn {
  display: flex;
  gap: 5px;
}

.filter-btn button {
  background: var(--primary-mandy);
  border: 0;
  outline: none;
  color: var(--primary-lite-white);
  border-radius: 77px;
  width: 104px;
  padding: 8px;
  font-size: 14px;
  line-height: 15.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
}


/* Pagination Css  */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  overflow: hidden;
}

.pagination-container::-webkit-scrollbar {
  display: none;
}

.pagination-container button {
  margin: 4px 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transition: all 0.25s;
}

.pagination-container button.pg-num {
  width: 1.8rem;
  height: 1.8rem;
}

.pagination-container button.nxt-btn, .pagination-container button.pre-btn {
  padding: 2px 16px;
}

.pagination-container button:hover {
  color: var(--primary-mandy);
  background-color: var(--primary-mandy);
  color: var(--primary-lite-white);
  border-radius: 50px;
}

.pagination-container button.active {
  background-color: var(--primary-mandy);
  color: white;
  border-radius: 100px;
}








/* Mobile  */
@media (max-width: 431px) {

  .products-heading-section {
    margin-bottom: 16px;
  }

  .product-listing-page-content-body {
    margin-top: 2rem;
  }

  .products-heading-section h2 {
    font-size: 18px;
  }

  .pagination-container button.pg-num {
    height: auto;
  }

  .filter-btn button {
    width: 92px;
    font-size: 12px;
    padding: 4px;
  }

}


.product-listing-page-content-body .product-img {
  background-color: white;
}

.product-listing-page-content-body img {
  object-fit: contain;
}