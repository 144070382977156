
.login-page-links {
  display: grid;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  gap: 12px;
  margin-top: 4px;
}

.login-page-links a {
  color: #2b2b2b;
}

.login-page-links .forget-password-login-page {
  font-size: 10px;

}
.login-page-links .forget-password-login-page a {
  color: #7CB9E8;
  font-weight: 700;
}



/* Mobile  */
@media (max-width: 431px) {
  .login-page-links {
    text-align: center;
  }
  .login-page-logo {
    display: none;
  }
}