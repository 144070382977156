.footer-compo-main-container {
  margin: 2rem 0;
}

.footer-compo-main-container>div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  margin: 0 5%;
  align-items: center;
}

.footer-compo-main-container .logo {
  width: 130px;
  height: 44px;
}

.footer-compo-main-container .logo .logo-container {
  position: unset;
  width: inherit;
}





.footer-compo-main-container>div:nth-child(1)>div:nth-child(2) {
  display: flex;
  gap: 32px;
  font-size: 16px;
  font-weight: 600;
}





.footer-compo-main-container>div:nth-child(1)>div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-compo-main-container>div:nth-child(1)>div:nth-child(3)>a {
  text-decoration: auto;
  color: currentColor;
  display: flex;
  align-items: center;
}

.footer-compo-main-container>div:nth-child(1)>div:nth-child(3)>a>svg {
  color: var(--primary-mandy);
  font-size: 20px;
}



.footer-compo-main-container>hr {
  border: 1.5px solid var(--primary-mandy);
  margin: 1.2rem 3%;
}


.footer-compo-main-container>div:nth-child(3) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  font-size: 14px;
  font-weight: 400;
  margin: 0 5%;
}




@media (max-width: 1280px) {
  .footer-compo-main-container>div:nth-child(1), .footer-compo-main-container>div:nth-child(3) {
    margin: 0 3%;
  }

  .footer-compo-main-container>hr {
    margin: 1.2rem 1.5%;
  }
}