/* The container */
.label-container {
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 700;
}

/* Hide the browser's default checkbox */
.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 23px;
  width: 23px;
  background-color: transparent;
  border: 1px solid var(--primary-mandy);
  border-radius: 7px;
  outline: none;
}

/* On mouse-over, add a grey background color */
.label-container:hover input~.checkmark {
  background-color: #eb53620e;
}

/* When the checkbox is checked, add a blue background */
.label-container input:checked~.checkmark {
  background-color: var(--primary-mandy);
  outline: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-container .checkmark:after {
  left: 8px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid var(--primary-lite-white);
  border-width: 0 2.5px 2.5px 0;
  transform: rotate(40deg);
}

.more-input-box {
  background: transparent;
  border: 0;
  border-bottom: 1px solid var(--primary-mandy);
  outline: none;
}

.more-input-box::-webkit-input-placeholder {
  color: var(--primary-mandy);
  font-size: 12px;
  font-weight: 600;
}