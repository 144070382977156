.c-u-f-name-section,
.c-u-f-email-phone-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.c-u-f-name-section input,
.c-u-f-email-phone-section input {
  width: 45%;
}

.c-u-f-name-section input,
.c-u-f-email-phone-section input,
.c-u-f-textarea-section textarea {
  padding: 12px;
  border-radius: 10px;
  border: 1px solid var(--primary-mandy);
  outline: none;
}

.c-u-f-textarea-section textarea {
  width: 100%;
  max-width: 100%;
  min-width: 30%;
  max-height: 12rem;
  min-height: 4rem;
  margin-bottom: 2rem;
}

.c-u-f-confirm-checkbox-section {
  margin-bottom: 2rem;
}

.c-u-f-confirm-checkbox-section span label {
  color: var(--primary-lite-black);
}

.c-u-f-btn-section button {
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  padding: 16px 22px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.label-container a {
  text-decoration: none;
}
.lbl-privacy-policy{
  font-size: 12px;
}