/* Profile viewer with Rating */
.user-img-w-r {
  margin-right: 7px;
}

.user-img-w-r i {
  border-radius: 50%;
  background: var(--primary-mandy);
  display: flex;
  width: 41px;
  height: 41px;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.user-img-w-r i svg {
  height: 35px;
  width: 24px;
  margin-top: 10px;
  color: white;
}

.user-name-w-r p {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--primary-lite-black);
}