
.chat-box-story-container {
  width: inherit;
  height: inherit;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 12px;
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.chat-box-user-img {
  display: flex;
  justify-content: center;
}

.chat-box-user-img span {
  border-radius: 50%;
  background: #eb5362;
  display: flex;
  width: 84px;
  height: 84px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
}

.chat-box-user-img span svg {
  height: 72px;
  width: 52px;
  margin-top: 16px;
}

.chat-box-user-name {
  margin-top: 8px;
  font-size: 15px;
  font-weight: 700;
}








/* Mobile  */
@media (max-width: 431px) {

  .chat-box-user-img span {
    width: 60px;
    height: 60px;
  }

  .chat-box-user-img span svg {
    width: 36px;
    height: 50px;
  }

}