



.imprint-main-container .i-m-c-inner {
  margin: 4rem 5%;
  width: 50%;
}


.imprint-main-container .i-m-c-inner h1 {
  font-size: 60px;
  font-weight: 700;
  color: var(--primary-dim-black);
}

.imprint-main-container .i-m-c-inner > div {
  margin-bottom: 5rem;
}

.imprint-main-container .i-m-c-inner > div > h6 {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-gray);
}

.imprint-main-container .i-m-c-inner > div > div {
  margin: 2rem 0;
}

.imprint-main-container .i-m-c-inner > div > div > h6 {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-gray);
}

.imprint-main-container .i-m-c-inner > div > p {
  font-size: 20px;
  font-weight: 400;
  color: var(--primary-gray);
}

.imprint-main-container .i-m-c-inner > div > div > p {
  font-size: 20px;
  font-weight: 400;
  color: var(--primary-gray);
}


h6, p {
  margin: 10px 0;
}

