
/* .range_container {} */

.range_container .sliders_control {
  padding: 1rem 0;
  position: relative;
}


.range_container .sliders_control input {
  width: 100%;
}

.range_container .sliders_control input[type=range]::-webkit-slider-thumb  {
  -webkit-appearance: none;
  pointer-events: all;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--primary-mandy);
  background-color: var(--primary-mandy);
  cursor: pointer;
  z-index: 999;
}

.range_container .sliders_control input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 15px;
  height: 15px;
  background-color: var(--primary-mandy);
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--primary-mandy);
  cursor: pointer;  
}


.range_container .sliders_control input[type=range]::-webkit-slider-thumb:hover {
  background: var(--primary-mandy);
}

.range_container .sliders_control input[type=range]::-webkit-slider-thumb:active {
  box-shadow: inset 0 0 3px var(--primary-mandy), 0 0 9px var(--primary-mandy);
  -webkit-box-shadow: inset 0 0 3px var(--primary-mandy), 0 0 9px var(--primary-mandy);
}

.range_container .sliders_control input[type="range"] {
  -webkit-appearance: none; 
  appearance: none;
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: var(--primary-mandy);
  pointer-events: none;
}