/* Befor Login  */

.befor-login {
  text-align: center;
}

.befor-login .header-upper-div {
  height: 28px;
  background: var(--primary-mandy);
  color: var(--primary-white);
  margin-bottom: 1rem;
}

.befor-login .header-upper-div div {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

.befor-login .header-upper-div div a {
  border-bottom: 1px solid white;
  margin-left: 5px;
}


/* Header */
.bl-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
}

.bl-header .left-side-content {
  display: flex;
  align-items: center;
  gap: 36px;
}

.bl-header .left-side-content .logo {
  width: 130px;
  height: 44px;
}

.bl-header .left-side-content .logo .logo-container {
  position: unset;
  width: inherit;
}

.bl-header .left-side-content .header-navlinks {
  display: flex;
  gap: 30px;
}

.menu-options .header-navlinks {
  display: grid;
  /* gap: 10px; */
  padding-top: 4rem;
  position: relative;
}

.menu-options .header-navlinks > .active {
  color: var(--primary-mandy);
}

.menu-options .header-navlinks > svg {
  position: absolute;
  top: 16px;
  right: 12px;
}

.bl-header .left-side-content .header-navlinks a,
.menu-options .header-navlinks a {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  color: var(--primary-gray);
  transition: all 0.25s;
}

.bl-header .left-side-content .header-navlinks a:hover,
.menu-options .header-navlinks a:hover {
  color: var(--primary-mandy);
  /* text-shadow: 0 1px 0 #ea546394,
    0 2px 0 #ea546370,
    0 3px 0 #ea54634c,
    0 4px 0 #ea546322;
  margin-top: -2px; */
}



/* Right side og header / Login and register button  */
.bl-header .right-side-content .menu-btn {
  display: none;
}

.bl-header .right-side-content .menu-btn svg {
  width: 18px;
  height: 18px;
}

.content-header .profile-viewer-icon {
  display: none;
}

.c-h-left-side {
  width: 70%;
}

.c-h-left-side > div:nth-child(1) .mobile-sidebar-container {
  display: none;
}




/* Menu Options CSS  */
.menu-btn .menu-options {
  position: absolute;
  min-width: 170px;
  background-color: var(--primary-lite-white);
  top: 0;
  right: 0;
  /* border: 1px solid #ffc2c2; */
  border-radius: 32px 0 0 32px;
  overflow: hidden;
  width: 45%;
  height: 100vh;
  z-index: 99;
}

.menu-options .header-navlinks a {
  padding: 8px 10px;
  /* border-bottom: 1px solid black; */
}

.menu-options .header-navlinks a:last-child {
  border: 0;
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--primary-white);
  z-index: 1100;
  box-shadow: 0px 2px 60px -4px #00000030;
}



@media (max-width: 1280px) {
  .bl-header {
    padding: 1rem 3%;
  }
}


/* Mobile */
@media (max-width: 431px) {

  .content-header div .profile-viewer {
    display: none;
  }

  .content-header .profile-viewer-icon {
    display: block;
  }

  .bl-header {
    padding: 8px 16px;
  }

  .bl-header .left-side-content .logo {
    width: 120px;
    height: 36px;
  }

  .bl-header .left-side-content .header-navlinks {
    display: none;
  }

  .log-reg-btns {
    display: none;
  }

  .befor-login .header-upper-div div {
    font-size: 10px;
  }

  .befor-login .header-upper-div {
    margin-bottom: 14px;
    height: 24px;
  }

  .bl-header .right-side-content .menu-btn {
    display: block;
  }

  .bl-header .right-side-content .menu-btn svg {
    cursor: pointer;
  }

  .c-h-left-side {
    display: flex;
    gap: 5px;
    width: -webkit-fill-available;
    margin-right: 4px;
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0 32px 32px 0;
    background-color: var(--primary-lite-white);
    padding: 1rem 0.5rem;
    min-width: 170px;
    width: 50%;
    height: 100vh;
    height: -webkit-fill-available;
    z-index: 9999;
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container .footer-navlinks {
    position: absolute;
    bottom: 12px;
    left: 0.5rem;
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container .footer-navlinks a {
    padding: 0;
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container .footer-navlinks .sidebar-element {
    padding: 8px 10px;
    transition: all 0.25s;
    width: fit-content;
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container .footer-navlinks .sidebar-element svg {
    width: 20px;
    height: 20px;
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container .footer-navlinks .sidebar-element p {
    font-size: 16px;
    font-weight: 500;
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container .footer-navlinks .sidebar-element:hover {
    color: var(--primary-mandy);
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container .header-navlinks {
    padding: 0;
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container .header-navlinks .menuCloseBtn {
    position: absolute;
    top: 12px;
    right: 8px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 2px;
  }

  .c-h-left-side > div:nth-child(1) .mobile-sidebar-container .header-navlinks a {
    width: fit-content;
  }




}