/* Hero Section star rating  */

.star-rating {
  display: none;
  /* gap: 4px; */
}

.star-rating svg {
  width: 20px;
  height: 20px;
  margin-top: -1px;
}

.star-rating span {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-bluewood);
  margin-left: 4px;
}