/* star rating  */

.star-rating {
  display: flex;
  gap: 1px;
  align-items: center;
}

.star-rating svg {
  width: 12px;
  height: 12px;
  margin-top: 2px;
}

.star-rating span {
  font-size: 13px;
  font-weight: 400;
  color: var(--primary-lite-black);
}

.star-rating {
  display: none;
}