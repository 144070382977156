/* Seller Profile Page Profile viewer with Rating */
.user-img-w-r-SPPPV {
  margin: 0;
}

.user-img-w-r-SPPPV i {
  border-radius: 50%;
  background: var(--primary-mandy);
  display: flex;
  width: 6rem;
  height: 6rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
}

.user-img-w-r-SPPPV i svg {
  height: 5rem;
  width: 4rem;
  margin-top: 1.5rem;
}


.user-name-w-r-SPPPV h4 {
  margin: 0;
  margin-top: 5px;
}

.user-name-w-r-SPPPV .star-rating {
  justify-content: center;
}

.user-name-w-r-SPPPV .star-rating svg {
  height: 12px;
  width: 12px;
}

.user-name-w-r-SPPPV .star-rating span {
  font-size: 10px;
  font-weight: 700;
  color: #525151;
}