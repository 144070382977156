/* main container  */
.seller-form-page-container {
  display: flex;
  width: 100%;
}

/* content container  */
.seller-page-container {
  width: 80%;
  background-color: #fadcdf;
}

.seller-page-container .seller-page-content {
  margin: 0 4rem;
  margin-top: 1.4rem;
}

.seller-form-page-location-map {
  margin-top: 20px;
  margin-right: 30px;
}

/* Form Main Container  */
.form-main-container {
  width: auto;
  border-radius: 1.5rem;
  background-color: var(--primary-lite-white);
  margin: 4rem 0;
  padding: 2rem;
  display: flex;
  gap: 20px;
}

.img-section {
  width: 50%;
}

.data-section {
  width: 50%;
}

.main-img {
  background-color: #d9d9d9;
  width: 100%;
  border-radius: 12px;
  height: 387px;
  position: relative;
}

.add-img-btn {
  position: absolute;
  right: 14px;
  bottom: 17px;
  cursor: pointer;
  width: 65px;
  height: 65px;
  background-color: #fff;
  border-radius: 5px;
}



.add-img-btn label img {
  cursor: pointer;
}

.other-imgs {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.other-imgs div {
  background-color: #d9d9d9;
  width: 138px;
  height: 120px;
  border-radius: 12px;
}

/* .whats-included-checkboxes {

} */

.whats-included-checkboxes h6 {
  margin-top: 26px;
  margin-bottom: 8px;
  color: var(--primary-lite-black);
  font-size: 13px;
  font-weight: 700;
}

.checkboxes {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}


/* item-description-section */
.item-description-section {
  padding: 0;
}

.item-description-section h6 {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
  color: var(--primary-lite-black);
}

.item-description-section textarea {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid var(--primary-mandy);
  width: 100%;
  outline: none;
  padding: 0;
  min-width: 200px;
  max-width: 400px;
}

.item-description-section textarea::-webkit-input-placeholder {
  font-size: 13px;
  font-weight: 700;
  color: var(--primary-lite-black);
}


/* Contact Information Section  */
.contact-info-section > div {
  display: grid;
  gap: 10px;
}

.contact-info-section > div > p {
  margin: 0;
  margin-top: 12px;
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-mandy);
}

.contact-info-section > div > input,
  .contact-info-section > div > div {
  /* width: 100%; */
  min-width: 200px;
  max-width: 400px;
  font-size: 18px;
  font-weight: bold;
  color: #00000050;
  padding: 10px 12px;
  border: 1px solid var(--primary-mandy);
  border-radius: 12px;
  outline-color: var(--primary-mandy);
}

.contact-info-section > div > div {
  display: flex;
  align-items: center;
  background-color: white;
  gap: 12px;
}

.contact-info-section > div > div > input {
  background-color: transparent;
  border: 0;
  outline: none;
  font-size: 18px;
  font-weight: bold;
  color: #00000050;

}

.contact-info-section > div > div > div > button {
  border: 0;
  padding: 0;
  
}

.contact-info-section > div > div > div > button > span {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  color: #00000080;
}



/* Data section  */
.data-section-header {
  display: flex;
}

.h-m-icons i {
  border-radius: 50%;
  background: var(--primary-mandy);
  display: flex;
  width: 41px;
  height: 41px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;;
}

.h-m-icons i svg {
  color: white;
  margin-top: 2px;
  width: 21px;
  height: 21px;
}

.form-choose-signal {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}

.choose-signal {
  width: 92px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #D9D9D9;
  padding: 0 11px;
  border-radius: 50px;
}

.choose-signal i {
  height: 9px;
  width: 9px;
  background-color: #6D6D6D;
  border-radius: 50%;
}

.choose-signal span {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: var(--primary-lite-black);
}


/* form inputs  */
.form-inputs {
  width: 94%;
  margin-top: 2rem;
}

.input-type-text {
  gap: 6px;
  display: grid;
}

.input-type-text input {
  width: 100%;
  background: #ffffff4f;
  border: 1px solid #eb5362;
  border-radius: 6px;
  padding: 8px;
  outline: none;
  font-size: 18px;
}

.input-type-text .sfp-price-input {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  background: #ffffff4f;
  border: 1px solid #eb5362;
  border-radius: 6px;
  padding: 8px;
  outline: none;
}

.input-type-text .sfp-price-input > input {
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
}

.input-type-text .sfp-price-input > span {
  line-height: 5px;
  font-weight: 500;
  color: #666;
}

.select-boxes {
  margin-top: 8px;
  display: grid;
  gap: 8px;
  
}

.select-boxes select {
  width: 100%;
  background: #ffffff4f;
  border: 1px solid #eb5362;
  border-radius: 6px;
  padding: 8px;
  outline: none;
  color: dimgray;
  font-size: 18px;
}

.choose-material-section {
  margin-top: 1.5rem;
}

.choose-material-section h6 {
  margin: 0;
  color: var(--primary-mandy);
  margin-bottom: 11px;
  font-size: 16px;
  font-weight: 700;
}

/* .materials-row {
  display: flex;
  gap: 10px;
} */

.materials-row div {
  background: var(--primary-lite-gray);
  color: var(--primary-lite-black);
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.materials-row div label {
  cursor: pointer;
}

.materials-row div label input {
  display: none;
}

.materials-row div.active {
  background: var(--primary-mandy);
  color: var(--primary-lite-white);
}

.choose-size-slider {
  margin: 1.5rem 0;
}

.choose-size-slider .c-s-s-tumb-lable {
  position: relative;
  margin: 2rem 0;
}

.choose-size-slider .c-s-s-tumb-lable label {
  padding: 8px 18px;
  background-color: var(--primary-mandy);
  color: var(--primary-lite-white);
  border-radius: 5px;
  bottom: -12px;
}

.choose-size-slider h6 {
  margin: 0;
  color: var(--primary-mandy);
  margin-bottom: 8px;
}

.list-my-surfboard-btn {
  margin-top: 1.2rem;
  margin-top: 25px;
  /* text-align: center; */
}

.list-my-surfboard-btn button {
  font-size: 18px;
  font-weight: 400;
  color: var(--primary-lite-white);
}

.list-my-surfboard-btn p {
  margin-top: 23px;
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  margin-inline: 36px;
}

.form-description-for-mobile {
  display: none;
}

.mobile-main-img {
  display: none;
}


/* Map css  */
.seller-form-page-location-map #map {
  border: 1px solid var(--primary-mandy);
  border-radius: 8px;
  height: 240px !important;
}


.lmsb-mobile {
  display: none;
}

.contact-info-section > div > div .ReactFlagsSelect-module_selectOptionValue__vS99- {
  font-size: 16px;
}

.contact-info-section.mobile, .item-description-section.mobile {
  display: none;
}


/* Mobile  */
@media (max-width: 431px) {

  .form-main-container {
    padding: 14px 12px;
    margin-bottom: 0;
    z-index: 1;
    position: relative;
    margin-top: 19.6rem;
  }

  .img-section {
    display: none;
  }

  .data-section {
    width: 100%;
  }

  .form-inputs {
    width: 100%;
  }

  .checkboxes {
    display: inline;
  }

  .checkboxes .checkbox {
    margin-right: 8px;
    margin-bottom: 10px;
    display: inline-block;
  }

  .main-img {
    z-index: 1;
    margin-top: -84px;
    position: fixed;
    border-radius: 0;
  }

  .mobile-main-img {
    display: block;
  }
  
  .form-description-for-mobile {
    display: block;
  }

  .lmsb-mobile {
    display: block;
  }

  .contact-info-section.mobile, .item-description-section.mobile {
    display: block;
    margin-bottom: 10px;
  }

}




.css-13cymwt-control {
  width: 100%;
  outline: none !important;
  background-color: #fff7 !important;
  border: 1px solid var(--primary-mandy) !important;
  border-radius: 12px !important;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-lite-black) !important;
  
  }
  
  .css-1xc3v61-indicatorContainer:hover, .css-13cymwt-control .css-1wy0on6 > div:nth-child(1):hover {
    color: hsl(0, 0%, 0%) !important; 
  }
  .css-13cymwt-control .css-1wy0on6 > div:nth-child(1) {
    font-size: 22px;
    height: 36px;
    width: 20px;
    font-weight: 700;
    line-height: 33px;
    padding: 0 !important;
    color: hsl(0, 0%, 50%) !important;
  }
  
  .css-1xc3v61-indicatorContainer {
    padding: 8px !important;
    color: hsl(0, 0%, 50%) !important;
    font-size: inherit !important;
    height: inherit !important;
    width: inherit !important;
  }
.ReactFlagsSelect-module_selectOption{
  padding: 0 8px;
  margin: 0;
}

input[placeholder="WhatsApp-Number"] {
  font-size: 14px;
}