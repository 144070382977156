.src-icon-box {
  display: inline-block;
  width: 100%;
}

.expend-search-btn {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.expend-search-btn .search-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  outline: none;
  transition: all 0.25 ease;
  cursor: pointer;
  left: 5px;
  top: 10px;
}

.expend-search-btn .search-box {
  width: 28px;
  background-color: rgba(255, 255, 255, 0.35);
  padding: 14px 22px;
  border-radius: 10px;
  outline: none;
  border: none;
  transition: all 0.25s ease;
}

.deactive .search-box {
  cursor: pointer;
}

.deactive .search-box::placeholder {
  color: transparent;
}

/* .icon-i-tag {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 4px 4px;
  align-items: center;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
} */

.icon-i-tag svg {
  width: 23px;
  height: 23px;
}


/* When Search Button Active  */

.active .search-box {
  width: 100%;
  font-size: 18px;
  height: 43px;
  background-color: rgba(255, 255, 255, 0.35);
  padding: 6px 8px;
  border-radius: 12px;
  outline: none;
  border: 1px solid #eb5362;
}

.active .search-btn {
  /* transform: translate(37.8rem, 2px); */
  /* right: 2.4rem; */
  left: 95.5%;
  top: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  padding: 0;
  margin: 0;
  outline: none;
}

.active .icon-i-tag {
  background-color: transparent;
}








@media (max-width: 431px) {
  
  .active .search-btn {
    left: 87.5%;
  }

}