
/* Hero Section Reviews css  */

.h-s-reviews {
  display: flex;
  gap: 16px;
}

.h-s-reviews .users-imgs {
  display: flex;
}

.h-s-reviews .users-imgs {
  margin-left: 12px;
}

.h-s-reviews .users-imgs .a-user-img {
  width: 40px;
  height: 40px;
  margin-left: -12px;
  border-radius: 50%;
  border: 2px solid var(--primary-white);
}


.reviews-star-and-text .rating-text {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-gray);
  line-height: 16px;
}