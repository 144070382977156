
/* Profile viewer css */
.seller-profile-p-v {
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
  margin-top: -5rem;
}

/* First row css */
.sp-page-fr-container {
  margin-top: 1.5rem;
}

.sp-page-fr-container .flex-container {
  display: flex;
  gap: 18px;
}

.sp-page-fr-container .flex-container .a-container {
  width: 30%;
  text-align: center;
}

.sp-page-fr-container .flex-container .a-container div {
  height: 7.5rem;
  background-color: #d7d7d7;
  border-radius: 8px;
}

.sp-page-fr-container .flex-container .a-container p {
  margin: 6px 0;
  font-size: 14px;
  font-weight: 500;
  color: #575757;
}

/* Second row css */
.sp-page-sr-container {
  margin-top: 1rem;
}

.sp-page-sr-container .flex-container {
  display: flex;
  gap: 18px;
}

.sp-page-sr-container .flex-container .a-container {
  width: 33%;
  text-align: center;
  height: 10rem;
  background-color: #d7d7d7;
  border-radius: 8px;
}

/* Third row css */
.sp-page-tr-container {
  margin-top: 1.5rem;
}

.sp-page-tr-container .flex-container {
  display: flex;
  gap: 14px;
}

.sp-page-tr-container .flex-container .a-container, .sp-page-tr-container .a-container {
  width: 45%;
  text-align: center;
  height: 16rem;
  background-color: #d7d7d7;
  border-radius: 8px;
}

.sp-page-tr-container .flex-container .container-c {
  width: 14%;
  height: 16rem;
  display: grid;
  gap: 10px;
} 

.sp-page-tr-container .flex-container .container-c .container-a {
  background-color: #d7d7d7;
  border-radius: 8px;
}

.sp-page-tr-container-mobile {
  display: none;
}




/* Mobile  */
@media (max-width: 431px) {

  .content-body {
    padding: 36px 12px;
  }

  .sp-page-fr-container {
    margin-top: 42px;
  }

  .sp-page-sr-container {
    overflow: auto;
    margin-top: 25px;
  }

  .sp-page-fr-container .flex-container {
    gap: 10px;
  }

  .sp-page-fr-container .flex-container .a-container div {
    height: 60px;
  }

  .sp-page-tr-container {
    margin-top: 14px
  }

  .sp-page-sr-container::-webkit-scrollbar {
    display: none;
  }

  .sp-page-sr-container .flex-container {
    width: fit-content;
    gap: 12px;
  }

  .sp-page-sr-container .flex-container .a-container {
    width: 220px;
    height: 120px;
  }

  .sp-page-tr-container-desktop {
    display: none;
  }

  .sp-page-tr-container-mobile {
    display: block;
  }

  .sp-page-tr-container .a-container {
    width: 100%;
    margin-bottom: 12px;
  }

  .sp-page-tr-container .flex-container .a-container {
    width: 75%;
    height: 212px;
  }

  .sp-page-tr-container .flex-container .container-c {
    width: 25%;
    height: 212px;
  }



}