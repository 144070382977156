

.success-page-container {
  height: 80vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.s-p-c-inner {
  display: flex;
  gap: 2.5rem;
}

.s-p-c-i-content-container {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-left: 8rem; */
}

.s-p-c-i-c-c-inner {
  width: 100%;
}

.s-p-c-i-content-container p, .mobile-success-text {
  font-size: 30px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 42px;
  line-height: 36px;
}

.s-p-c-i-content-container button {
  padding: 11px;
  width: 14.2rem;
  font-size: 35px;
  font-weight: 900;
}

.image-container {
  text-align: center;
}

.success-page-container .image-container .banner-img {
  width: 70%;
  margin: 0;
}

.mobile-success-text{
  display: none;
}






/* Mobile  */
@media (max-width: 431px) {

  .s-p-c-inner {
    display: grid;
    gap: 10px;
  }

  .image-container {
    display: grid;
  }

  .image-container .banner-img {
    width: auto;
  }

  .s-p-c-i-content-container {
    display: block;
    margin-left: 0;
  }

  .s-p-c-i-content-container p {
    display: none;
  }

  .mobile-success-text {
    display: block;
    margin: 0;
  }

}