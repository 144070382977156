

.t-o-u-m-c-inner .heading-part,
.t-o-u-m-c-inner .content-part {
  margin: 50px 5%;
}

.t-o-u-m-c-inner .heading-part h1 {
  font-weight: 700;
  font-size: 60px;
  color: var(--primary-dim-black);
}

.t-o-u-m-c-inner .heading-part p {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-gray);
}


.t-o-u-m-c-inner .content-part {
  display: grid;
  gap: 2rem;
}

.t-o-u-m-c-inner .content-part > div > p, ul li {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-gray);
}

ul {
  display: grid;
  gap: 10px;
}

.t-o-u-m-c-inner .content-part .second-content {
  margin-top: 2rem;
}