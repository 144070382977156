body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f4f4f4; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

/* Colors  */
:root {
  --primary-mandy: #EA5462;
  --primary-lite-mandy: rgba(234, 84, 98, 0.2);
  --primary-dark-mandy: #c44350;

  --primary-white: #FFFFFF;
  --primary-lite-white: #F4F3F3;

  --primary-gray: #475467;
  --primary-lite-gray: #D2D2D2;

  --primary-black: #000000;
  --primary-dim-black: #101828;
  --primary-lite-black: #4F4F4F;

  --primary-bluewood: #344054;

  --primary-mirage: #101828;

  --primary-transparent: rgb(255 255 255 / 85%);
}
.p-red-clr {color: #EA5462;}
.p-red-bg {background-color: #EA5462;}



.main-container {
  display: flex;

}

.content-container {
  background-color: #fadcdf;
}

.content-container-inner-div {
  margin: 0 70px;
  padding-top: 23px;
} 

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 60px; */
}

.content-body {
  width: auto;
  border-radius: 30px 30px 0 0;
  background-color: rgba(244, 243, 243, 1);
  padding: 36px 30px;
  position: relative;
}

.container {
  width: 100%;
  height: 100vh;
  display: grid;
}

.grid {
  display: grid;
  gap: 2.5rem;
}

.grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}

.grid-three-column {
  grid-template-columns: repeat(3, 1fr);
}

.d-flex {
  display: flex;
}

.gap-5px {
  gap: 5px;
}

.mb-5rem {
  margin-bottom: 5rem;
}

@media (max-width: 431px) {
  
}

/* @media (min-width: 30em) and (max-width: 50em) {} */