/* Filter Page css */

.filter-pop-up-main-container {
  height: fit-content;
  margin: 4rem 0;
  width: auto;
  border-radius: 30px;
  background-color: rgba(244, 243, 243, 1);
  padding: 36px 30px;
  position: relative;
  z-index: 99;
}

.filter-page-container-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 38px;
}

.filter-page-container-heading .page-name {
  font-size: 39px;
  font-weight: 700;
}

.filter-page-container-heading .filter-btns {
  display:flex;
  gap:5px
}

.filter-page-container-heading .filter-btns span:nth-child(1) svg {
  width:40px;
  height: 30px;
}

.filter-page-container-heading .done-button {
  display: flex;
  align-items: center;
}

.filter-page-container-heading .done-button svg {
  width: 36px;
  height: 26px;
  transition: all 0.25s;
  cursor: pointer;
}


.filter-page-container-heading .done-button:hover svg {
  color: var(--primary-mandy);
}


/* Filter Container  */
/* .filter-page-content-container {} */

.filter-page-lable-of-input {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-mandy);
  margin-bottom: 8px;
}

.filter-page-content-container .f-p-c-c-inner-div {
  display: flex;
  gap: 34px;
}

.f-p-c-c-inner-div .f-p-c-c-first-column, .f-p-c-c-inner-div .f-p-c-c-second-column {
  min-width: 408px;
  max-width: min-content;
}

.f-p-c-c-first-column .fpccfc-size-range,
.f-p-c-c-first-column .fpccfc-choose-material,
.f-p-c-c-first-column .fpccfc-thickness-range, 
.f-p-c-c-second-column .fpccsc-surf-level {
  margin: 24px 0;
}





/* Mobile  */
@media (max-width: 431px) {

  .filter-pop-up-main-container {
    width: -webkit-fill-available;
    padding: 14px 12px;
  }

  .filter-page-content-container .f-p-c-c-inner-div {
    display: grid;
  }

  .filter-page-container-heading .page-name {
    font-size: 18px;
  }

  .f-p-c-c-inner-div .f-p-c-c-first-column, .f-p-c-c-inner-div .f-p-c-c-second-column {
    min-width: auto;
    max-width: 430px;
  }

}