

.menu-icon-container {
  display: none;
}





/* Mobile */
@media (max-width: 431px) {

  .menu-icon-container {
    width: 44px;
    height: 43px;
    background-color: rgba(255, 255, 255, 0.35);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
  }
  
  .menu-icon-container svg {
    color: var(--primary-black);
    width: 20px;
    height: 18px;
  }
  

  .menu-icon-container .notify-dot {
    border: 2px solid #ffffffdb;
    width: 9px;
    height: 9px;
    margin-left: -8px;
    margin-top: -11px;
  }
}