/* Image container  */
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container .banner-img {
  width: 55%;
  margin-left: 20%;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container .inner-form-container {
  text-align: center;
  width: 55%;
  margin-right: 20%;
}

.form-container div h4 {
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 4px 0;
  line-height: 20px;
}

.form-container div p {
  font-weight: 500;
  margin: 0;
  font-size: 15px;
  text-align: center;
  line-height: 18px;
}

.icon-btn {
  border-radius: 50px;
  border: 1.5px solid black;
  padding: 14px 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  font-size: 18px;
  font-weight: 400;
}

.icon-btn img {
  width: 16px;
  height: 17px;
}

.icon-btn-facebook {
  border: 0;
  color: white;
  background: #4444ff;
}

.icon-btn-email {
  border: 0;
  color: white;
  background: #ff3d73;
  width: 100%;
}

.icon-btn a {
  text-decoration: none;
  color: white;
}

.sign-up-form {
  gap: 12px;
  padding: 16px 0;
}

.sign-up-form input {
  border-radius: 35px;
  padding: 16px 20px;
  border: 1.8px solid black;
  outline: none;
  background-color: transparent;
}

.sign-up-form input::placeholder {
  font-size: 18px;
  font-weight: 400;
  color: var(--primary-lite-gray);
}

.label-of-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label-of-checkbox input {
  height: 27px;
  width: 27px;
  cursor: pointer;
}

.label-of-checkbox div {
  line-height: 15px;
  margin-left: 6px;
  cursor: auto;
  margin-top: -2px;
}

.sign-up-btn {
  border-radius: 50px;
  border: 0;
  padding: 14px 80px;
  cursor: pointer;
  width: fit-content;
  margin: auto;
  color: white;
  background: rgba(234, 84, 98, 1);
  margin-top: 30px;
  font-size: 30px;
  font-weight: 900;
  line-height: 36px;
}

.signup-page-links,
.signup-page-links a {
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 400;
}

.mobile-container {
  display: none;
}

.form-container .inner-form-container .sing-up-with-google-btn,
.form-container .inner-form-container .sing-up-with-fb-btn {
  display: none;
}





/* Mobile  */
@media (max-width: 431px) {

  .container {
    display: none;
  }

  .mobile-container {
    display: block;
  }

  .m-c-inner {
    margin: 50px 34px;
  }

  .m-c-i-heading-container {
    text-align: center;
  }

  .m-c-i-heading-container h4 {
    font-weight: 400;
    font-size: 16px;
    margin: 0 0 4px 0;
    line-height: 20px;
  }

  .m-c-i-heading-container p {
    font-weight: 500;
    margin: 0;
    font-size: 18px;
    text-align: center;
    line-height: 16px;
  }

  .m-c-i-image-container {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }

  .m-c-i-image-container .banner-img {
    width: 264px;
    /* height: 348px; */
  }

  .m-c-i-btns-container {
    display: grid;
    gap: 12px;
  }

  .m-c-i-btns-container .icon-btn {
    width: 100%;
  }

  .signup-page-links,
  .signup-page-links a {
    font-size: 13px;
  }

  .sign-up-btn {
    margin-top: 20px;
  }

  .login-page-link {
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
  }

}

@media (min-width: 432px) and  (max-width: 767px) { 
  .m-c-i-heading-container p {
    font-weight: 500;
    margin: 0;
    font-size: 20px;
    text-align: center;
    line-height: 16px;
  }
  
}