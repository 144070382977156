
/* Saved page css  */

.saved-container .content-body {
  margin-top: 4rem;
}

.saved-container .dashboard-p-v {
  margin-bottom: 1.5rem;
}

.saved-container h2 {
  text-align: center;
  font-size: 28px;
  font-weight: 700;

}


