/* Account Settings css  */

.account-settings-container .content-body {
  margin-top: 4rem;
}

.account-settings-container .dashboard-p-v {
  margin-bottom: 1.5rem;
}

.a-s-c-content-container .a-s-c-c-c-item {
  margin: 0 4rem;
}

.account-setting-hr {
  border: 1px solid var(--primary-black);
  margin: 4rem 0 2rem 0;
}


@media screen and (max-width: 431px) {

  .account-settings-container .dashboard-p-v {
    margin-bottom: 0.5rem;
  }

  .a-s-c-content-container .a-s-c-c-c-item {
    margin: 0;

  }
  /* .a-s-c-content-container .a-s-c-c-c-item:last-child{
    display: none;
  } */
  .change-password-container{
    visibility: hidden;
    display: block;
  }

  .profile-setting-heading-and-btn {
    padding: 0;
  }

  .profile-setting-heading-and-btn h2 {
    font-size: 20px;
  }

  .profile-setting-heading-and-btn button {
    font-size: 14px;
    padding: 6px 18px;
  }

  .profile-setting-container form {
    display: grid;
  }

  .profile-setting-container form div {
    width: 100%;
  }

  .profile-setting-container form div div {
    gap: 5px;
    margin-bottom: 0.5rem;
  }

  .profile-setting-container form div div label {
    font-size: 18px;
  }

  .profile-setting-container form div div input {
    padding: 12px;
  }

  .account-setting-hr {
    margin: 1rem 0 0.5rem 0;
  }

  .change-password-container {
    margin-bottom: 2rem;
  }

  .change-password-container form input {
    padding: 12px;
    font-size: 14px;
  }
}
