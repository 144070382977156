/* Dashboard Profile Viewer  */
.dashboard-p-v {
  margin-top: -5.6rem;
  margin-bottom: 60px;
}

/* Dashboard First Row  */
.dashboard-first-row {
  overflow: auto;
}

.dashboard-first-row .inner-db-fr {
  display: inline-flex;
  gap: 16px;
  width: 100%;
  justify-content: space-evenly;
}

.dashboard-first-row .inner-db-fr .db-fr-elem {
  display: inline-flex;
  width: 33%;
  height: 170px;
  border-radius: 15px;
  background-color: var(--primary-mandy);
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid var(--primary-gray-border);
}

.db-fr-elem .db-fr-content-container {
  display: inline-block;
  color: var(--primary-white);
}

.db-fr-elem .db-fr-content-container h4 {
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 12px;
}

.db-fr-elem .db-fr-content-container .db-fr-icon {
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Plus icon  */
.db-fr-elem .db-fr-content-container .plus-icon svg {
  width: 36px;
  height: 36px;
}

/* chats-icon */
.db-fr-elem .db-fr-content-container .chats-icon svg {
  width: 45px;
  height: 42px;
}

.db-fr-elem .db-fr-content-container .chats-icon svg g path {
  fill: #fff;
}




/* Dashboard Second Row  */
.dashboard-second-row {
  margin-top: 60px;
}

/* .dashboard-second-row .db-sr-inner {
  display: flex;
  text-align: center;
  justify-content: center;
}*/

.dashboard-second-row .db-sr-inner .db-sr-elems {
  display: flex;
  justify-content: center;
}

.dashboard-second-row .db-sr-inner .db-sr-elem {
  width: 80%;
  background-color: #fff;
  min-height: 400px;
  border-radius: 30px;
  padding: 40px 45px;
}

.dashboard-second-row .db-sr-inner .db-sr-elem h6 {
  margin: 0;
  margin-bottom: 12px;
  font-size: 30px;
  font-weight: bold;
}

.dashboard-second-row .db-sr-inner .db-sr-elem p {
  margin: 0;
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 400;
}

.dashboard-second-row .db-sr-inner .db-sr-elem p:nth-child(3),
.dashboard-second-row .db-sr-inner .db-sr-elem p:nth-child(4) {
  margin-bottom: 26px;
}

.dashboard-second-row .db-sr-inner .db-sr-elem p>span {
  font-weight: 500;
}

.dashboard-second-row .db-sr-inner .db-sr-elem button {
  margin-top: 30px;
  font-size: 26px;
  font-weight: 500;
  background-color: var(--primary-mandy);
  color: white;
  padding: 0.5rem 2.5rem;
  border: 0;
  outline: none;
  border-radius: 100px;
}

.dashboard-second-row .db-sr-inner .db-sr-elem button a {
  text-decoration: none;
  color: currentColor;
}

/*.dashboard-second-row .db-sr-inner .db-sr-elem div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 140px;
  border-radius: 15px;
  background-color: #EEEEEE;
  border: 2px solid var(--primary-gray-border);
} */






@media screen and (max-width: 431px) {

  .content-body {
    margin-top: 2.5rem !important;
  }

  .dashboard-p-v {
    margin-bottom: 30px;
    margin-top: -3rem;
  }

  .dashboard-first-row .inner-db-fr .db-fr-elem {
    width: 100%;
    height: 130px;
  }

  .db-fr-elem .db-fr-content-container h4 {
    font-size: 18px;
    font-weight: 600;
  }

  .db-fr-elem .db-fr-content-container .db-fr-icon {
    height: 50px;
  }

  .dashboard-second-row {
    margin-top: 30px;
  }

  .dashboard-second-row .db-sr-inner .db-sr-elem {
    width: 100%;
    border-radius: 10px;
    padding: 10px 15px;
  }

  .dashboard-second-row .db-sr-inner .db-sr-elem h6 {
    font-size: 22px;
    margin-bottom: 5px;
  }

  .dashboard-second-row .db-sr-inner .db-sr-elem p {
    font-size: 16px;
  }

  .dashboard-second-row .db-sr-inner .db-sr-elem p:nth-child(3),
  .dashboard-second-row .db-sr-inner .db-sr-elem p:nth-child(4) {
    margin-bottom: 10px;
  }

  .dashboard-second-row .db-sr-inner .db-sr-elem button {
    margin-top: 24px;
    font-size: 18px;
  }

  .dashboard-second-row .db-sr-inner .db-sr-elem .feedback-form-btn-div {
    text-align: center;
  }

}