
/* Home Page Css  */

main.home-main-container {
  background-color: #fff !important;
  overflow: hidden;
  margin: 0;
}

.home-main-container .hero-section {
  margin: 4rem 5%;
}




/* Filter btn section css  */
.home-page-filter-btn-section {

  padding: 2rem;
}

.h-p-f-b-s-inner {
  display: grid;
  gap: 16px;
  justify-content: center;
  text-align: center;
}

.h-p-f-b-s-i-text {
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -2px;
}

.h-p-f-b-s-i-btn {
  display: flex;
  justify-content: center;
}

.h-p-f-b-s-i-btn div, .h-p-f-b-s-i-btn div .filter-btn {
  width: 100%;
  display: contents;
}

.h-p-f-b-s-i-btn div .filter-btn button {
  width: 90%;
}





/* Products container css  */
/* .home-main-container .home-page-products-container {
  background: linear-gradient( var(--primary-lite-mandy) 56%, var(--primary-white) 44% );
} */

/* .home-main-container .home-page-products-container .h-p-p-c-inner {
  margin: 4rem 5%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
} */


.h-p-f-b-s-i-btn > a {
  text-decoration: none;
  color: var(--primary-white);
  background-color: var(--primary-mandy);
  padding: 8px 20px;
  border-radius: 50px;
}



@media screen and (max-width: 1280px) {
  .home-main-container .hero-section {
    margin: 4rem 3%;
  }

  .home-main-container .home-page-products-container .h-p-p-c-inner {
    margin: 4rem 3%;
    margin-bottom: 1rem;
  }
}


 

/* Mobile  */
@media screen and (max-width: 431px) {

  .home-main-container .hero-section {
    margin: 4rem 16px;
    margin-bottom: -4rem;
  }

  .home-main-container .home-page-products-container {
    background: #fff;
    margin-bottom: 12rem;
  }

  .home-page-filter-btn-section {
    margin-top: 80px;
  }

  .h-p-f-b-s-i-text  {
    font-size: 15px;
    line-height: normal;
    letter-spacing: normal;
  } 

}

@media screen and (min-width: 432px) and (max-width: 750px) {
  .h-p-f-b-s-i-text  {
    font-size: 26px;
    line-height: normal;
  }

}
