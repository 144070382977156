/* Chat Box css file  */

.content-body-chatbox {
  min-height: 100vh !important;
}

.content-body-chatbox .text-messages {
  margin-bottom: 1rem;
  overflow: auto;
}

.chatbox-p-v {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: -15px;
}

.text-messages div {
  display: inline-block;
  line-height: 23px;
}

.message-recieved,
.message-sent {
  margin: 10px 0;
}

.message-recieved {
  padding: 6px 12px;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 12px;
  width: 65%;
  font-size: 13px;
  font-weight: 400;
  min-width: 51%;
  max-width: 66%;
  width: fit-content;
}

.message-sent {
  padding: 6px 12px;
  background-color: rgba(255, 213, 213, 0.35);
  border-radius: 12px;
  width: 65%;
  float: right;
  font-size: 13px;
  font-weight: 400;
  text-align: end;
  min-width: 51%;
  max-width: 66%;
  width: fit-content;
}

.message-recieved p,
.message-sent p {
  margin: 0;
}

.message-sent p {
  text-align: left;
}

.message-recieved .tym,
.message-sent .tym {
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  margin-right: 1rem;
  color: rgba(79, 79, 79, 0.5);
  font-weight: 400;
}


/* Message input container  */
.msg-input-container form {
  display: flex;
  position: fixed;
  bottom: 12px;
  width: 66%;
  padding-right: inherit;
  align-items: center;
}

.msg-input-container .msg-input {
  width: -webkit-fill-available;
  outline: none;
  border: 0;
}

.msg-input-container .msg-input div {
  display: flex;
  gap: 6px;
  align-items: center;
  height: 46px;
  background: rgba(217, 217, 217, 1);
  padding: 0 8px;
  border-radius: 50px;
}

.msg-input-container .msg-input div span {
  display: flex;
  align-items: center;
  margin: 0 2px;
}

.msg-input-container .msg-input div span svg {
  height: 30px;
  width: 30px;
  color: rgba(234, 84, 98, 1);
  cursor: pointer;
}

.msg-input-container .msg-input div input {
  width: -webkit-fill-available;
  outline: none;
  border: 0;
  margin-right: 10px;
  background-color: transparent;
  font-size: 18px;
}

.msg-input-container .msg-input div input::placeholder {
  font-size: 18px;
}

/* Message Send Button  */
.msg-send-btn button {
  border: none;
  background: transparent;
}