/* Hero Section css  */
.hero-section-component {
  display: flex;
  justify-content: space-between;
}


/* Text Section  */
.hero-section-component .h-s-text-section {
  width: 50%;
  /* overflow: hidden; */
}

.hero-section-component .h-s-text-section .any-new-msg, .any-new-msg {
  border: 1.5px solid var(--primary-mandy);
  border-radius: 18px;
  padding: 3px 10px 3px 4px;
  display: inline-flex;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  line-height: 18px;
  margin-bottom: 16px;
}

.hero-section-component .h-s-text-section .any-new-msg .border, .any-new-msg .border{
  border: 1.5px solid var(--primary-mandy);
  border-radius: 16px;
  padding: 2px 10px;
}

.hero-section-component .h-s-text-section .any-new-msg .arrow-icon, .any-new-msg .arrow-icon {
  display: flex;
  align-items: center;
  gap: 7px;
}

.hero-section-component .h-s-text-section .any-new-msg .arrow-icon svg, .any-new-msg .arrow-icon svg {
  color: var(--primary-mandy);
}

.hero-section-component .h-s-text-section .hero-section-heading {
  font-size: 60px;
  line-height: 72px;
  font-weight: 700;
  letter-spacing: -2px;
  color: #101828;
}

.hero-section-component .h-s-text-section .hero-section-p1,
.hero-section-component .h-s-text-section .hero-section-p2 {
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-gray);
}

.hero-section-component .h-s-text-section .hero-section-p1 {
  font-weight: 700;
  margin: 24px 0;
}

.hero-section-component .h-s-text-section .hero-section-p2 {
  font-weight: 400;
}

.hero-section-component .h-s-text-section .hero-section-btns {
  display: inline-block;
  margin: 48px 0;
}




/* Image Section  */
.hero-section-component .h-s-img-section {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.h-s-img-section .h-s-img-section-inner {
  position: relative;
}


/* blob  */
.h-s-img-section .h-s-img-section-inner .h-s-i-s-blob {
  height: 28rem;
  width: 30rem;
  border-radius: 58% 43% 33% 64% / 50% 38% 53% 50%;
  background: var(--primary-lite-mandy);
  box-shadow: inset 6px 33px 20px 0 var(--primary-lite-mandy),
    inset 20px 80px 15px 0 var(--primary-lite-mandy);
  /* 0px 2px 130px 2px #eac8c8; */
}

/* .h-s-img-section .h-s-img-section-inner .h-s-i-s-blob::before {
  content: '';
  position: absolute;
  border-radius: 37% 54% 46% 46%;
  background: var(--primary-mandy);
  width: 50px;
  transform: rotate(-30deg);
  height: 15px;
  top: 20px;
  left: 20px;
}

.h-s-img-section .h-s-img-section-inner .h-s-i-s-blob::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  background: var(--primary-mandy);
  width:10px;
  height: 10px;
  top: 60px;
  left: 20px;
} */



.h-s-img-section .h-s-img-section-inner .h-s-imgs {
  position: absolute;
  top: -6rem;
  left: 4.5rem;
}

.h-s-img-section .h-s-img-section-inner .h-s-imgs .iphone-img {
  position: relative;
  z-index: 10;
  width: 24rem;
  height: 43rem;
}

.h-s-img-section .h-s-img-section-inner .h-s-imgs .a-man-banner-img {
  position: absolute;
  z-index: 8;
  width: 483px;
  height: 638px;
  left: 10.4rem;
  top: 2rem;
}


.hero-section-component .h-s-text-section .hero-section-reviews {
  display: none;
} 

.h-s-imgs .iphone-img img {
  object-fit: fill !important;
}



/* Mobile  */
@media (max-width: 431px) {

  .hero-section-component {
    display: inline-block;
    width: -webkit-fill-available;
  }

  .hero-section-component .h-s-text-section {
    width: 100%;
  }

  .hero-section-component .h-s-text-section .any-new-msg {
    font-size: 12px;
  }

  .hero-section-component .h-s-text-section .hero-section-heading {
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .hero-section-component .h-s-text-section .hero-section-p1 {
    display: none;
  }

  .hero-section-component .h-s-text-section .hero-section-p2 {
    font-size: 18px;
    line-height: 28px;
    overflow: hidden;
    height: 7rem;
  }

  .hero-section-component .h-s-text-section .hero-section-btns {
    margin: 32px 0;
    width: 100%;
  }

  .hero-section-component .h-s-text-section .hero-section-reviews {
    display: none;
  }

  .hero-section-component .h-s-img-section {
    width: 100%;
    /* padding-top: 4rem; */
  }

  .h-s-img-section .h-s-img-section-inner .h-s-i-s-blob {
    height: 30rem;
    width: 32rem;
  }

  .h-s-img-section .h-s-img-section-inner .h-s-imgs {
    top: -1rem;
    left: 4rem;
  }

  .h-s-img-section .h-s-img-section-inner .h-s-imgs .iphone-img {
    width: 18rem;
    height: 32rem;
  }

  .h-s-img-section .h-s-img-section-inner .h-s-imgs .a-man-banner-img {
    width: 22rem;
    height: 27rem;
    left: 6.4rem;
    top: 2rem;
  }
}