

.go-back-btn {
  border: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-lite-black);
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.go-back-btn svg {
   font-size: 24px;
}