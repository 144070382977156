/* Product Section css  */
.product-section-row {
  text-align: center;

}


/* Product Container  */
.product-section-row .p-s-r-a-product {
  display: inline-block;
  width: 30%;
  padding: 1% 3%;
  /* background-color: var(--primary-lite-white); */
}


/* Product Image  */
.product-section-row .p-s-r-a-product .p-s-r-a-product-img {
  width: 100%;
  height: 240px;
  border-radius: 16px;
}


/* Product Text  */
.product-section-row .p-s-r-a-product .p-s-r-a-product-text {
  margin-top: 20px;
}

.product-section-row .p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-price {
  display: flex;
  gap: 1px;
  align-items: center;
  color: var(--primary-mandy);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

/* Euro Icon */
.product-section-row .p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-price svg {
  font-size: 11.4px;
  margin-top: -2px;
}



/* Product Name Container  */
.product-section-row .p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
}

/* Product Name  */
.product-section-row .p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-name .p-n {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: var(--primary-mirage) ;
}

.p-s-r-a-p-name .p-n a {
  color: var(--primary-mirage) ;
}
/* Arrow Icon  */
.product-section-row .p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-name .a-i svg {
  width: 24px;
  height: 24px;
  rotate: -45deg;
  color: var(--primary-mirage);
  transition: all 0.25s;
}

.product-section-row .p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-name .a-i:hover svg {
  color: var(--primary-mandy);
  margin-top: -4px;
}


/* Description  */
.product-section-row .p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--primary-gray);
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  text-align: left;
}


/* Product Details  */
.p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-details {
  position: relative;
  overflow: hidden;
}

/* Arrow Button to Next Element container */
.p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-details .next-arrow-btn {
  position: absolute;
  right: 0px;
  height: -webkit-fill-available;
  align-items: center;
  display: flex;
  cursor: pointer;
}

/* Arrow Button to Next Element Svg */
.p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-details .next-arrow-btn svg {
  box-shadow: 0px 0px 14px 14px var(--primary-white);
  background: var(--primary-white);
}

.p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-details .next-arrow-btn:hover svg {
  color: var(--primary-mandy);
}


.p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-details .p-d-wrapper {
  overflow: auto;
  display: -webkit-inline-box;
  gap: 8px;
  padding-right: 22px;
}

.p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-details .p-d-wrapper::-webkit-scrollbar {
  display: none;
}

.p-s-r-a-product .p-s-r-a-product-text .p-s-r-a-p-details .p-d-wrapper .p-d-a-detail {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--primary-mandy);
  background-color: var(--primary-lite-mandy);
  border: 1px solid var(--primary-mandy);
  border-radius: 16px;
  padding: 2px 10px;
}


.home-main-container .home-page-products-container .h-p-p-c-inner  {
  display: flex;
  flex-direction: column;
  gap: 20px;
}





/* Mobile  */
@media (max-width: 431px) {

  .product-section-row {
    display: grid;
    overflow: unset;
  }

  .home-main-container .home-page-products-container .h-p-p-c-inner {
    margin: 4rem 16px;
  }

  .product-section-row .p-s-r-a-product {
    width: 95%;

  }

}

@media (max-width: 420px) {
  .product-section-row .p-s-r-a-product {
      width: 90%;
  }
}

@media (max-width: 400px) {
  .product-section-row .p-s-r-a-product {
      width: 85%;
  }
}

@media (max-width: 370px) {
  .product-section-row .p-s-r-a-product {
      width: 73%;
  }
}