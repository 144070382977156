/* Hero Section Buttons  */

.sell-and-explore-btn {
  display: flex;
  gap: 12px;
}

.sell-and-explore-btn .f-btn,
.sell-and-explore-btn .s-btn {
  border: 1px solid rgba(234, 84, 98, 0.4);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 16px 22px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  transition: all 0.35s;
}

.sell-and-explore-btn .f-btn {
  background-color: transparent;
  color: var(--primary-mandy);
}

.sell-and-explore-btn .f-btn:hover {
  background-color: var(--primary-mandy);
  color: var(--primary-white);
}

.sell-and-explore-btn .s-btn {
  background-color: var(--primary-mandy);
  color: var(--primary-white);
}

.sell-and-explore-btn .s-btn:hover {
  background-color: #c54753;
}



/* Mobile  */
@media (max-width: 431px) {

  .sell-and-explore-btn .f-btn,
  .sell-and-explore-btn .s-btn {
    padding: 12px 18px;
    font-size: 16px;
    font-weight: 500;
  }

  .sell-and-explore-btn {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 12px;
    width: 100%;
  }

  .sell-and-explore-btn .f-btn, .sell-and-explore-btn .s-btn {
    width: 100%;
  }
}