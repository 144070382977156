
.primary-clr-btn {
  background: rgba(234, 84, 98, 1);
  color: rgba(244, 243, 243, 1);
  border: 0;
  border-radius: 5rem;
  cursor: pointer;
}

.send-msg-icon-btn {
  border-radius: 50%;
  background: rgba(234, 84, 98, 1);
  display: flex;
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}

.send-msg-icon-btn svg {
  width: 24px;
  height: 24px;
  margin-right: 2px;
  color: rgba(244, 243, 243, 1);
  rotate: 12deg;
}



/* Header Login and Register button  */
.log-reg-btns {
  display: flex;
  gap: 12px;
}

.log-reg-btns .btn1,
.log-reg-btns .btn2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 16px;
  border-radius: 8px;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.30s;
}

.log-reg-btns .btn1 {
  background-color: transparent;
}

.log-reg-btns .btn1:hover {
  background-color: var(--primary-mandy);
  color: var(--primary-lite-white);
}

.log-reg-btns .btn2 {
  background-color: var(--primary-mandy);
  color: var(--primary-lite-white);
}

.log-reg-btns .btn2:hover {
  background-color: var(--primary-dark-mandy);
}