/* Dashboard Page Profile viewer */

.dasboard-profile-viewer {
  display: grid;
  justify-items: center;
  gap: 5px;
}

.user-img-DBPPV {
  margin: 0;
}

.user-img-DBPPV i {
  border-radius: 50%;
  background: var(--primary-mandy);
  display: flex;
  width: 6rem;
  height: 6rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid var(--primary-lite-white);
}

.user-img-DBPPV i svg {
  color: var(--primary-white);
  height: 85px;
  width: 60px;
  margin-top: 1.2rem;
}


.user-name-DBPPV h4 {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
}



@media screen and (max-width:431px) {

  .dasboard-profile-viewer {
    gap: 2px;
  }

  .user-name-DBPPV h4 {
    font-size: 20px;
  }

  .user-img-DBPPV i {
    width: 4rem;
    height: 4rem;
  }

  .user-img-DBPPV i svg {
    height: 54px;
  }
}