.content-body {
  height: auto;
}

.product-content {
  display: flex;
  gap: 1.5rem;
}

.pp-other-imgs-upper-div {
  position: relative;
}

.pp-other-imgs {
  margin-top: 1rem;
  overflow: auto;
  position: relative;
}

.pre-next-arrow-icons {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  top: 44%;
}

.pp-other-imgs-upper-div:hover .pre-next-arrow-icon svg {
  display: flex;
}

.pre-next-arrow-icon {
  z-index: 3;
}

.pre-next-arrow-icon svg {
  display: none; 
  box-shadow: 0px 0px 18px 8px rgba(255, 255, 255, 0.900);
  background: rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.pp-other-imgs::-webkit-scrollbar {
  display: none;
}

.pp-other-imgs .other-imgs-inner {
  display: inline-flex;
  gap: 12px;
}

.pp-other-imgs .other-imgs-inner .single-img {
  width: 138px;
  height: 120px;
  display: block;
  border-radius: 12px;
  background-color: #d9d9d9;
  cursor: pointer;
  z-index: 2;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}


/* Data Section  */
.data-section .p-v-like-msg-container {
  display: flex;
  justify-content: space-between;
}

.data-section .p-v-like-msg-container .msg-like-container {
  display: flex;
  gap: 8px;
}

.available-signal {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}

.available-signal .signal {
  width: 92px;
  height: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: rgba(102, 208, 168, 0.24);
  border-radius: 52px;
}

.available-signal .signal i {
  height: 10.2px;
  width: 10.2px;
  background-color: rgba(102, 208, 168, 1);
  border-radius: 50%;
}

.available-signal .signal span {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}


/* Name and rate css  */
.plp-name-and-rate .plp-name {
  font-size: 24px;
  font-weight: 700;
}

.plp-name-and-rate .plp-rate {
  margin-top: -10px;
}

.plp-name-and-rate .plp-rate span {
  font-size: 47px;
  font-weight: 700;
}

.plp-name-and-rate .plp-rate svg {
  height: 35px;
  width: 22px;
}


/* Product details  */
.plp-product-details {
  margin: 8px 0;
}

.plp-product-details .a-detail {
  display: inline-grid;
  text-align: center;
  margin-right: 24px;
  margin-bottom: 6px;
}

.plp-product-details .a-detail .detail-name {
  font-size: 10px;
  line-height: 23px;
  font-weight: 400;
}

.plp-product-details .a-detail .detail-value {
  /* width: 100px;
  height: 21px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F8E9E9;
  border-radius: 77px;
  border: 1px solid var(--primary-mandy);
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #4F4F4F;
  padding: 4px 16px;
}

/* Included Item  */
.plp-included-item-container {
  margin-bottom: 1rem;
}

.plp-included-item-container .included-heading,
.item-description-heading {
  font-size: 13px;
  font-weight: 700;
  margin: 10px 0;
}

.plp-included-item-container .inner-included-item {
  display: inline-flex;
  gap: 6px;
}

.plp-included-item-container .inner-included-item .a-included-item {
  width: 88px;
  height: 34px;
  font-size: 13px;
  font-weight: 400;
  background: var(--primary-mandy);
  color: var(--primary-white);
  border-radius: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Item Description css  */
.plp-item-description .inner-item-description {
  margin-top: -5px;
}

.plp-item-description .inner-item-description p {
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
}

.main-img-mobile {
  display: none;
}


.product-content-body {
  margin-top: 2.5rem;
  z-index: 2;
}

.pp-other-imgs-upper-div-mobile {
  display: none;
}

.h-m-icons {
  display: flex;
  gap: 10px;
}

.sold-out-btn button {
  display: flex;
  align-items: center;
}

.sold-out-btn button {
  border: 0;
  background-color: var(--primary-mandy);
  color: var(--primary-white);
  font-size: 16px;
  border-radius: 18px;
  padding: 6px 14px;
  cursor: pointer;
}




/* Mobile  */
@media (max-width: 431px) {

  .content-body {
    padding: 15px 12px;
  }

  .main-img-mobile {
    display: block;
  }

  .available-signal {
    margin-top: 10px;
  }

  .plp-name-and-rate .plp-rate {
    margin-top: -2px;
  }

  .plp-name-and-rate .plp-name {
    font-size: 18px;
  }

  .plp-name-and-rate .plp-rate span {
    font-size: 24px;
  }

  .plp-name-and-rate .plp-rate svg {
    height: 18px;
    width: 16px;
  }

  .plp-product-details {
    margin: 2px 0;
  }

  .plp-product-details .a-detail {
    margin-right: 4px;
    margin-bottom: 2px;
  }

  .plp-product-details .a-detail .detail-name {
    line-height: 16px;
  }


  .product-content-body {
    margin-top: 18.5rem;
  }

  .pp-other-imgs-upper-div-mobile {
    display: block;
  }

  .product-page-on-mobile {
    margin-top: 18.5rem !important;
  }

}

@media screen and (max-width: 700px) {
  .go-back-btn {
    position: -webkit-fixed; /* For Safari */
    position: fixed;

  }
}

.main-img {
  /* height: 100% !important; */
}

.product-pg-main-img {
  background-color: white;
}

.product-pg-main-img > img {
  object-fit: contain;
}


.product-pg-other-imgs .single-img {
  background-color: white !important;
}

.product-pg-other-imgs img {
  object-fit: contain;
}