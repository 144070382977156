/* Selectable Button Component css  */

.selectable-button {
  width: 120px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 13px;
  outline: none;
  color: var(--primary-lite-black);
  background-color: var(--primary-lite-gray);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.selectable-button-active {
  background: var(--primary-mandy);
  color: var(--primary-lite-white);
}





/* Mobile  */
@media (max-width: 431px) {

  .selectable-button {
    height: 36px;
    font-size: 16px;
  }

}