

/* Range Inputs css  */

/* .range-inputs-container {} */

.range-inputs-container .r-i-c-inputs {
  display: flex;
  gap: 14px;
}

.range-inputs-container .r-i-c-inputs input {
  width: 50%;
  border: 1px solid var(--primary-mandy);
  outline: none;
  padding: 10px 11px;
  border-radius: 12px;
  background: #ffffff70;
}

.range-inputs-container .r-i-c-inputs input::placeholder {
  font-size: 18px;
  font-weight: 700;
}



/* Choose Brand  css  */

/* .choose-brand-container {}  */

.choose-brand-container .c-b-c-dropdown select {
  width: 100%;
  height: 43px;
  outline: none;
  background-color: #fff7;
  border: 1px solid var(--primary-mandy);
  border-radius: 12px;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-lite-black);
  padding: 0 8px;
} 

.choose-brand-container .c-b-c-dropdown select option {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-lite-black);
  background-color: var(--primary-lite-mandy);
}




/* Choose Material component css  */

/* .choose-material-container {} */

.choose-material-container .c-m-c-buttons {
  display: flex;
  justify-content: space-between;
}

.choose-material-container .c-m-c-buttons button {
  width: auto;
  padding: 0 12px;
}






/* Whats Included Componenets */

.whats-included-container .wic-checkboxes {
  display: flex;
  gap: 10px;
}

.fpccsc-choose-location {
  position: relative;
}



/* checkbox buttons for choose material */
.c-m-c-b-inner .active {
  background-color: black;
}






/* Surf Level component css */

/* .surf-level-container{} */

.surf-level-container .slc-buttons {
  display: flex;
  justify-content: space-between;
}

/* .css-1xc3v61-indicatorContainer:nth-child(1) {
  display: none;
} */
.css-13cymwt-control {
width: 100%;
outline: none;
background-color: #fff7 !important;
border: 1px solid var(--primary-mandy) !important;
border-radius: 12px !important;
font-size: 16px;
font-weight: 700;
color: var(--primary-lite-black) !important;

}

.css-1xc3v61-indicatorContainer:hover, .css-13cymwt-control .css-1wy0on6 > div:nth-child(1):hover {
  color: hsl(0, 0%, 0%) !important; 
}
.css-13cymwt-control .css-1wy0on6 > div:nth-child(1) {
  font-size: 22px;
  height: 36px;
  width: 20px;
  font-weight: 700;
  line-height: 33px;
  padding: 0 !important;
}

.css-1xc3v61-indicatorContainer {
  padding: 8px !important;
  color: hsl(0, 0%, 50%) !important;
  font-size: inherit !important;
  height: inherit !important;
  width: inherit !important;
}
