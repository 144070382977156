


.sell-my-surf-main-container {
  overflow: hidden;
}

/* .s-m-s-m-c-inner {} */

.hero-section {
  margin: 4rem 5%;
}


.s-m-s-m-c-inner .h-s-img-section .h-s-img-section-inner .h-s-i-s-blob {
  margin-left: -10rem;
}

.s-m-s-m-c-inner .h-s-img-section .h-s-img-section-inner .h-s-imgs {
  left: 0;
}

.s-m-s-m-c-inner .h-s-img-section .h-s-img-section-inner .h-s-imgs .a-man-banner-img {
  left: 4rem;
  top: 1rem;
}

.s-m-s-m-c-inner .h-s-text-section .any-new-msg .border {
  display: flex;
  align-items: center;
  gap: 8px;
}

.s-m-s-m-c-inner .h-s-text-section .any-new-msg .border i {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #7cf572;
  border-radius: 50%;
  box-shadow: 0px 3px 5px -2px black
}

.s-m-s-m-c-inner .hero-section-component .h-s-text-section .any-new-msg, .any-new-msg {
  padding-right: 4rem;
  color: var(--primary-mandy);
}

.s-m-s-m-c-inner .hero-section-component .h-s-text-section .hero-section-heading {
  font-size: 50px;
  line-height: 62px;
}

.s-m-s-m-c-inner .hero-section-component .h-s-text-section .hero-section-p1,
.s-m-s-m-c-inner .hero-section-component .h-s-text-section .hero-section-p2  {
  width: 110%;
}