/* Profile Setting Container  */

/* .profile-setting-container {

} */


.profile-setting-heading-and-btn {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  margin-bottom: 12px;
  align-items: center;
}

.profile-setting-heading-and-btn h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.profile-setting-heading-and-btn button {
  font-size: 18px;
  font-weight: 600;
  border: 1px solid var(--primary-mandy);
  color: var(--primary-mandy);
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 22px;
  cursor: pointer;
}


.profile-setting-container form {
  display: flex;
  justify-content: space-between;
}

.profile-setting-container form div {
  width: 45%;
}

.profile-setting-container form div div {
  width: auto;
  display: grid;
  gap: 10px;
  margin-bottom: 1rem;
}

.profile-setting-container form div div label {
  font-size: 24px;
  font-weight: 700;
}

.profile-setting-container form div div input {
  border: 2px solid black;
  border-radius: 100px;
  padding: 16px;
  padding-top: 20px;
  background-color: transparent;
  min-width: 330px;
  width: 100%;
}

.profile-setting-container form div div input::placeholder {
  font-size: 20px;
  font-weight: 400;
  color: #161616;
}


.profile-setting-container form div .change-username-field {
  display: none;
}