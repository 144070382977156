.a-product-container {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
  width: 198px;
}

.a-product-container .product-img {
  width: 100%;
  height: 228px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.a-product-container .product-img span {
  position: absolute;
  bottom: 6px;
  right: 5px;
  border-radius: 50%;
  background: rgba(234, 84, 98, 1);
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.a-product-container .product-img span svg {
  color: white;
}


/* Product Name And Price css  */
.product-name-and-price .product-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
}

.product-name-and-price .product-price-and-owner-name {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: -4px;
}

.product-name-and-price .product-price-and-owner-name .ow-name {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  color: rgba(79, 79, 79, 1);
  line-height: 15.6px;
  width: 40%;
  overflow: hidden;
}

.product-name-and-price .product-price-and-owner-name .price {
  color: rgba(234, 84, 98, 1);
  margin: 0;
}

.product-name-and-price .product-price-and-owner-name .price span {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
}

.product-name-and-price .product-price-and-owner-name .price svg {
  font-size: 20px;
  margin-left: -2px;
  margin-bottom: -1px;
}







/* Mobile  */
@media (max-width: 431px) {

  .a-product-container {
    margin-right: 8px;
    margin-bottom: 18px;
    width: 184px;
  }

  .product-name-and-price .product-price-and-owner-name .price span {
    font-size: 18px;
  }

}

@media (max-width: 425px) {
  .a-product-container {
    width: 180px;
  }
}

@media (max-width: 416px) {
  .a-product-container {
    width: 175px;
  }
}

@media (max-width: 406px) {
  .a-product-container {
    width: 170px;
  }
}

@media (max-width: 396px) {
  .a-product-container {
    width: 165px;
  }
}

@media (max-width: 386px) {
  .a-product-container {
    width: 160px;
  }
}