/* range input css  */
.range-input-box input[type=range] {
  width: 100%;
  height: 2px;
  -webkit-appearance: none;
  margin-top: 1.5rem;
}

.range-input-box input[type=range]:focus {
  outline: none;
}

.range-input-box input[type=range]::-webkit-slider-runnable-track {
  cursor: pointer;
  background: #eb5362;
  height: 1.5px;
  border-radius: 5px;
}

.range-input-box input[type=range]::-webkit-slider-thumb {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #df5555;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}