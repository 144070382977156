/* Choose Location css */

/* .choose-location-container {} */

.location-name-input {
  width: 100%;
  border: 1px solid var(--primary-mandy);
  outline: none;
  padding: 10px 11px;
  border-radius: 12px;
  background: #ffffff70;
}

.location-name-input::placeholder {
  font-size: 16px;
  font-weight: 700;
}


/* Map Container  */
.choose-location-container .c-l-c-map-container {
  width: 100%;
  height: 248px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 12px;
  background: var(--primary-lite-mandy);
  position: relative;
}

.choose-location-container .c-l-c-map-container .c-l-c-m-c-iframe {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.choose-location-container .c-l-c-map-container .c-l-c-m-c-iframe iframe {
  border: none;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.clcmc-map-range {
  margin: 0 1.6rem;
  border: 1px solid var(--primary-mandy);
  border-radius: 8px;
  padding: 0 10px;
  background: var(--primary-transparent);
  position: absolute;
  width: -webkit-fill-available;
  top: 48px;
}

.clcmc-map-range .label {
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-weight: 700;
  color: var(--primary-lite-black);
  margin-bottom: -8px
}

.clcmc-map-range input {
  margin: 0;
  margin-bottom: 8px;
}

.clcmc-map-range input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 1px;
  width: 100%;
  background-color: var(--primary-mandy);
  cursor: pointer;
}

.clcmc-map-range input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 12px;
  height: 12px;
  background-color: var(--primary-mandy);
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--primary-mandy);
  cursor: pointer;
}


.clcmc-map-range input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--primary-mandy);
  background-color: var(--primary-mandy);
  cursor: pointer;
  z-index: 999;
}

.clcmc-map-range input[type=range]::-webkit-slider-thumb:hover {
  background: var(--primary-mandy);
}








/* Mobile  */
@media (max-width: 431px) {

  .choose-location-container .c-l-c-map-container {
    margin-bottom: 30px;
  }

}