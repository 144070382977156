/* Chats Page css file  */

.content-heading h2 {
  font-weight: 700;
  font-size: 40px;
  margin: 16px 0;
}

.content-body .inbox-grid {
  display: grid;
  gap: 2rem;
}

.content-body .inbox-grid a {
  text-decoration: none;
  color: currentColor;
}

.user-story-container {
  width: 61.2rem;
  overflow: auto;
  margin-bottom: 1rem;
}

.user-story-container .user-story-slider {
  display: inline-flex;
  gap: 12px;
}

.user-story-container .user-story-slider .my-story,
.user-story-container .user-story-slider .user-story {
  width: 155px;
  height: 165px;
}

.user-story-container::-webkit-scrollbar {
  display: none;
}

.my-story {
  position: relative;
}

.pls-icon-for-add-story {
  position: absolute;
  right: 32px;
  bottom: 50px;
}

.pls-icon-for-add-story div svg {
  color: rgba(234, 84, 98, 1);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background-color: rgba(244, 243, 243, 1);
  padding: 8px;
  width: 35px;
  height: 35px;
}

/* Messages box css  */
.a-message-row-container {
  display: flex;
  justify-content: space-between;
}

.a-msg-r-c-left-part {
  display: flex;
  gap: 10px;
}

.amrc-user-img span {
  border-radius: 50%;
  background: rgba(234, 84, 98, 1);
  display: flex;
  width: 83px;
  height: 83px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
}

.amrc-user-img span svg {
  height: 72px;
  width: 50px;
  margin-top: 1rem;
}

.amrc-user-name-n-last-msg {
  display: grid;
  align-items: baseline;
  align-content: space-around;
}

.amrc-user-name-n-last-msg h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

.amrc-user-name-n-last-msg p,
.a-msg-r-c-right-part p {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: rgba(79, 79, 79, 1);
}


.a-msg-r-c-right-part {
  display: grid;
  text-align: end;
  align-content: space-evenly;
}

.a-msg-r-c-right-part div .notify-signle {
  background: rgba(102, 208, 168, 1);
  color: rgba(244, 243, 243, 1);
  border-radius: 50%;
  padding-top: 2px;
  font-size: 15px;
  font-weight: 400;
  width: 26px;
  height: 26px;
  display: inline-block;
  text-align: center;
}


/* When typing is active css  */
.amrc-user-img {
  position: relative;
}

.typing-active {
  position: absolute;
  top: 68px;
  left: 16px;
}

.typing-active-inner {
  background: rgba(244, 243, 243, 1);
  border-radius: 70px;
  padding: 0px 2px;
  width: 50px;
  height: 22px;
  /* box-shadow: 0px 0px 7px 1px #00000029; */
  border: 1px solid rgba(211, 211, 211, 0.75);
}

.typing-active-inner svg {
  width: 14px;
  height: 22px;
}

.content-heading .c-h-profile-viewer {
  display: none;
}







/* Mobile  */
@media (max-width: 431px) {

  .content-heading h2 {
    display: none;
  }

  .content-heading .c-h-profile-viewer {
    display: block;
    margin-left: 12px;
  }

  .c-h-profile-viewer .p-v-container .user-name div svg {
    display: none;
  }

  .user-story-container {
    width: auto;
    margin-top: 1rem;
    margin-left: 12px;
  }

  .user-story-container .user-story-slider .my-story,
  .user-story-container .user-story-slider .user-story {
    width: 110px;
    height: 118px;
  }

  .pls-icon-for-add-story {
    right: 24px;
    bottom: 32px;
  }

  .pls-icon-for-add-story div svg {
    padding: 5px;
    width: 25px;
    height: 24px;
  }

  .amrc-user-img span {
    width: 60px;
    height: 60px;
  }

  .amrc-user-img span svg {
    height: 52px;
    width: 38px;
  }

  .typing-active {
    top: 50px;
    left: 12px;
  }

  .typing-active-inner {
    width: 36px;
    height: 16px;
  }

  .typing-active-inner svg {
    width: 10px;
    height: 16px;
  }

  .a-msg-r-c-right-part div .notify-signle {
    width: 19px;
    height: 19px;
    padding: 0;
  }

}