/* .sidebar-main-container {} */

.sidebar-main-container>div {
  width: 245px;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding: 125px 12px 20px 12px;
}

.sidebar-main-container>div::-webkit-scrollbar {
  display: none;
}


/* sidebar logo set  */
.sidebar-main-container>div>div:nth-child(1) {
  position: absolute;
  width: 160px;
  top: 1.4rem;
  left: 20px;
}

.sidebar-main-container>div>div:nth-child(1)>.logo-container {
  position: unset;
  width: inherit;
}



.sidebar-container {
  height: -webkit-fill-available;
}

.inner-sidebar-container {
  height: -webkit-fill-available;
  display: grid;
  align-content: space-between;
  gap: 1rem;
}





/* Laptop 1080 to 1280 */
@media (max-width: 1280px) {
  .sidebar-container {
    width: fit-content;
    width: -webkit-fill-available;
  }
}






/* Mobile  */
@media (max-width: 431px) {

  .sidebar-main-container {
    display: none;
  }
}