/* Change Password css  */

.change-password-container {
  margin-bottom: 4rem;
  display: none;
}

.change-password-container form {
  display: grid;
  gap: 24px;
}

.change-password-container form input {
  max-width: 340px;
  padding: 16px 20px;
  border: 2px solid black;
  border-radius: 100px;
  background-color: transparent;
}

.change-password-container form input::placeholder {
  font-size: 18px;
  font-weight: 400;
  color: #B6B6B6;
}