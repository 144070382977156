
.p-v-container {
  display: flex;
  align-items: center;
}

.user-img {
  margin-right: 12px;
}

.user-img i {
  border-radius: 50%;
  background: var(--primary-mandy);
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;

}

.user-img i svg {
  /* color: white;
  height: 4rem;
  width: 4rem; */
  margin-top: 8px;
}


.user-name p {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}

.user-name div {
  align-items: center;
  display: flex;
  gap: 4px;
  line-height: 10px;
}

.user-name div h4 {
  font-size: 24px;
  margin: 0;
  font-weight: 700;
  line-height: 28.8px;
}

.user-name div svg {
  height: 16px;
  margin-top: 6px;
  width: 16px;
}