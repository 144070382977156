

.contact-us-main-container {
  position: relative;
}



.c-u-m-c-content-container {
  margin: 4rem 5%;
  width: 58%;
}


.cumccci-page-heading {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -2px;
  color: var(--primary-dim-black);
}

.cumccci-page-description {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: var(--primary-gray);
  margin: 24px 0;
}


.cumc-banner-img {
  width: 660px;
  position: fixed;
  bottom: -1.5rem;
  right: -2rem;
  z-index: -1;

}

.cumc-banner-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 700px) {
  .c-u-m-c-content-container {
    width: 90%;
  }
  .cumc-banner-img {
    display: none;
  }
  .cumccci-page-heading {
    font-size: 40px;
  }
}