
.logo-container {
  width: 183px;
  position: fixed;
  top: 20px;
  left: 19px;
}

.logo-container img {
  width: 100%;
}