.profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-i-1,
.p-i-2 {
  margin: 0;
  display: inline-block;
}

/* .p-i-1 i, .p-i-2 i {
  box-shadow: 1px 1px 30px 0px #0000003d;
} */

/* profile image 1 */
.p-i-1 {
  z-index: 1;
}

.p-i-1 svg {
  height: 42px;
  width: 30px;
  margin-top: 8px;
  color: white;
}

.p-i-1 i {
  border-radius: 50%;
  background: rgba(234, 84, 98, 1);
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* profile image 2 */

.p-i-2 svg {
  height: 25px;
  width: 18px;
  margin-top: 5px;
  color: white;
}

.p-i-2 i {
  border-radius: 50%;
  background: rgba(234, 84, 98, 1);
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: -9px;
}


/* Profile text  */
.profile-text h4 {
  margin: 0;
  margin-top: 5px;
  font-size: 24px;
  font-weight: 700;
}

.profile-text p {
  margin: 0;
  font-size: 15px;
  color: rgba(79, 79, 79, 1);
  font-weight: 400;
}