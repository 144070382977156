

.cookie-consent {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 5%;
  background-color: var(--primary-mandy);
  position: fixed;
  width: -webkit-fill-available;
  bottom: 0;
  z-index: 999;
}

.cookie-consent p {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-lite-white);
}

.cookie-consent p a {
  /* text-decoration: none; */
  color: inherit;
}

.cookie-consent button {
  padding: 0.8rem 2.5rem;
  border: 0;
  outline: 0;
  background-color: var(--primary-white);
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-lite-black);
  cursor: pointer;
  border-radius: 5px;
}




@media screen and (max-width: 431px){
  .cookie-consent {
    display: grid;
    padding: 10px 12px ;
  }

  .cookie-consent p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
  }

  .cookie-consent button {
    padding: 8px;
  }
}