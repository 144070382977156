

.data-privacy-main-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.data-privacy-main-container .d-p-m-c-inner {
  margin: 0 5%;
}

.data-privacy-main-container .d-p-m-c-inner h1 {
  font-size: 60px;
  font-weight: 700;
  color: var(--primary-dim-black);
}